p {
    white-space: pre-line !important;
}

.rca-edit .actions {
    width: 100% !important;
    padding: 20px 0 !important;
    
}
.rca-edit .tabContainer {
    width: auto !important;
    display: block !important;
}
.rca-edit .tabContainer .rca-form-scaffold-content-container {
    flex-direction: column;
}

.rca-edit .tabContainer .rca-form-scaffold-content-container form {
    height: auto;
    margin-bottom: 100px;
}

.rca-edit .tabContainer .rca-form-scaffold-content-container .content h1 {
    font-size: 20px;
    margin-top: 20px;
}
.rca-edit .content  {
    padding: 0 !important;
}
.rca-edit .actions > div, .rca-edit .tabContainer {
    margin: 0 80px !important;
    @media only screen and (min-width: 1500px) {
        margin: 0 100px !important;
    }
    @media only screen and (min-width: 1650px) {
        margin: 0 auto !important;
        width: 100% !important;
        max-width: 1400px !important;
    }
}

.rca-edit .actions > div, .rca-edit .tabContainer .content {
    @media only screen and (min-width: 1650px) {
        max-width: 1400px !important;
    }
}

.nav-open .content-footer-card-outer-container, 
.nav-open .content-footer-card-outer-container:hover   {
    width: 100%;
}

@media (min-width:1000px) {
    .nav-open .content-footer-card-outer-container {
        width: calc(50% - 14.5px);
    }
    .nav-open .content-footer-card-outer-container:hover {
        width:  calc(50% - 12.5px);
    }
}

@media (min-width:1300px) {
    .nav-open .content-footer-card-outer-container {
        width: calc(33.3% - 18.5px);
    }
    .nav-open .content-footer-card-outer-container:hover {
        width: calc(33.3% - 16.5px);
    }
}

@media (min-width:1650px) {
    .nav-open .content-footer-card-outer-container {
        width:  calc(25% - 20.75px);
    }
    .nav-open .content-footer-card-outer-container:hover {
        width:  calc(25% - 18.75px);
    }
}